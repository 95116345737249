import type { LigneMetre } from '~/types/models/ligneMetre';

export type SortFunction = (a: LigneMetre, b: LigneMetre) => number;

export const useSortLignes = (
  lignes: LigneMetre[],
  sortFunctions: SortFunction[],
  direction: string = ':asc',
) => {
  const compareFunctions = sortFunctions.map((fn) => {
    if (direction === ':asc') {
      return (a: LigneMetre, b: LigneMetre) => fn(a, b);
    } else {
      return (a: LigneMetre, b: LigneMetre) => fn(b, a);
    }
  });

  lignes.sort((a, b) => {
    for (let i = 0; i < compareFunctions.length; i++) {
      const comp = compareFunctions[i](a, b);
      if (comp !== 0) {
        return comp;
      }
    }
    return 0;
  });
};
