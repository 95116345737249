import type { Article } from '~/types/models/article';

export const useEmptyLigne = (article: Article, tvaPercent: number, margePercent: number) => {
  return {
    id: crypto.randomUUID(),
    article,
    quantite: 0,
    formule_manuelle: '',
    description_notice: '',
    infos_complementaires: '',
    compris: true,
    prix_unitaire: null,
    article_code: null,
    article_libelles: null,
    article_description_notice: null,
    article_description_travaux: null,
    article_unite: null,
    article_prix: null,
    focused: false,
    marge: margePercent,
    tva: tvaPercent,
  };
};
