import { defineStore } from 'pinia';
import { useProjetsStore } from './projets';
import type { TerrainProjet } from '~~/types/models/terrainProjet';
import { DocumentCategory } from '~/types/Enums';
import { DocumentType } from '~/documents/types';

export const useTerrainsProjetsStore = defineStore('terrainsProjets', {
  state: () => ({
    terrain: null as TerrainProjet | null,
  }),
  getters: {
    currentProjet: (state): any => {
      return useProjetsStore().currentProjet;
    },

    missingDocuments: (state) => {
      const mandatoryDocs = useDocumentTypes()[DocumentCategory.TERRAIN_PROJET].filter(
        (d) => d.required,
      );

      const mdrDocTypes = mandatoryDocs.filter((d) => {
        if (!state.terrain?.proprietaire) {
          return ![
            DocumentType.ACTE_AUTHENTIQUE.name,
            DocumentType.TITRE_DE_PROPRIETE.name,
          ].includes(d.name);
        } else {
          return d.name != DocumentType.SOUS_SEING.name;
        }
      });

      const uploadedDocTypes = state.terrain?.documents?.map((d) => d.type);

      if (state.terrain?.proprietaire) {
        if (uploadedDocTypes?.includes(DocumentType.ACTE_AUTHENTIQUE.name)) {
          // remove TITRE_DE_PROPRIETE if ACTE_AUTHENTIQUE is uploaded
          mdrDocTypes.splice(
            mdrDocTypes.findIndex((d) => d.name === DocumentType.TITRE_DE_PROPRIETE.name),
            1,
          );
        }
        if (uploadedDocTypes?.includes(DocumentType.TITRE_DE_PROPRIETE.name)) {
          // remove ACTE_AUTHENTIQUE if TITRE_DE_PROPRIETE is uploaded
          mdrDocTypes.splice(
            mdrDocTypes.findIndex((d) => d.name === DocumentType.ACTE_AUTHENTIQUE.name),
            1,
          );
        }
      }

      const missingDocs = mdrDocTypes.filter((d) => !uploadedDocTypes?.includes(d.name));

      return missingDocs;
    },
  },
  actions: {
    async fetch() {
      if (this.currentProjet) {
        const res = await useApi.terrainsProjets.findOne(this.currentProjet.terrain_projet.id);
        if (res && res.data) {
          this.terrain = res.data;
        }
      }
    },

    async create(terrain: Creatable<TerrainProjet>) {
      const res = await useApi.terrainsProjets.create(terrain);
      if (res && res.data) {
        this.terrain = res.data;
      }
    },

    async update(terrain: Updatable<TerrainProjet>) {
      const res = await useApi.terrainsProjets.update(terrain);
      if (res && res.data) {
        this.terrain = res.data;
      }
      await useProjetsStore().fetchJalons();
    },

    async refresh() {
      if (this.terrain) {
        await this.fetch();
        await useProjetsStore().fetchJalons();
      }
    },

    reset() {
      this.terrain = null;
    },
  },
});
