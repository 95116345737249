import { defineStore } from 'pinia';
import { useProjetsStore } from './projets';
import type { Chantier } from '~/types/models/chantier';
import type { User } from '~/types/models/user';
import type { Projet } from '~/types/models/projet';

export const useChantiersStore = defineStore('chantiers', {
  state: () => ({
    chantier: null as Chantier | null,
    conducteurs: [] as User[],
  }),
  getters: {
    currentProjet: (): Projet | null => {
      return useProjetsStore().currentProjet;
    },
  },
  actions: {
    async fetch() {
      if (this.currentProjet?.chantier?.id) {
        const res = await useApi.chantiers.findOne(this.currentProjet.chantier.id);
        if (res && res.data) {
          this.chantier = res.data;
        }
      }
    },

    async fetchConducteurs() {
      this.conducteurs = await useApi.users.findByRoles([
        useConstants.roles.CS_CONDUC,
        useConstants.roles.CS_CONDUC_EXT,
      ]);
    },

    async create(chantier: Creatable<Chantier>) {
      const res = await useApi.chantiers.create(chantier);
      if (res && res.data) {
        this.chantier = res.data;
      }
    },

    async update(chantier: Updatable<Chantier>) {
      const res = await useApi.chantiers.update(chantier);
      if (res && res.data) {
        this.chantier = res.data;
      }
      await useProjetsStore().fetchJalons();
    },

    async refresh() {
      if (this.chantier) {
        await this.fetch();
        await useProjetsStore().fetchJalons();
      }
    },

    reset() {
      this.chantier = null;
    },
  },
});
