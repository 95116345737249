import { defineStore } from 'pinia';
import { useProjetsStore } from './projets';
import type { Prepachantier } from '~/types/models/prepachantier';

export const usePrepaschantiersStore = defineStore('prepaschantiers', {
  state: () => ({
    prepachantier: null as Prepachantier | null,
  }),
  getters: {
    currentProjet: (state): any => {
      return useProjetsStore().currentProjet;
    },
  },
  actions: {
    async fetch() {
      if (this.currentProjet) {
        const res = await useApi.prepaschantiers.findOne(this.currentProjet.prepachantier.id);
        if (res && res.data) {
          this.prepachantier = res.data;
        }
      }
    },

    async create(prepachantier: Creatable<Prepachantier>) {
      const res = await useApi.prepaschantiers.create(prepachantier);
      if (res && res.data) {
        this.prepachantier = res.data;
      }
    },

    async update(prepachantier: Updatable<Prepachantier>) {
      const res = await useApi.prepaschantiers.update(prepachantier);
      if (res && res.data) {
        this.prepachantier = res.data;
      }
      await useProjetsStore().fetchJalons();
    },

    async refresh() {
      if (this.prepachantier) {
        await this.fetch();
        await useProjetsStore().fetchJalons();
      }
    },

    reset() {
      this.prepachantier = null;
    },
  },
});
