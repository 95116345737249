import { useParamByKey } from './useParamByKey';
import { ParametreKeys } from '~/types/Enums';

export const useTvaValues = () => {
  const keys = [ParametreKeys.TAUX_TVA, ParametreKeys.TAUX_TVA_1, ParametreKeys.TAUX_TVA_2];

  return keys
    .map((key) => parseFloat(useParamByKey(key)?.valeur ?? '0'))
    .filter((v) => !!v)
    .map((v) => ({ id: v, name: `${v}%` }));
};
