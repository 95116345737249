import { defineStore } from 'pinia';
import { useProjetsStore } from './projets';
import type { CCI } from '~/types/models/cci';

export const useCcisStore = defineStore('ccis', {
  state: () => ({
    cci: null as CCI | null,
  }),
  getters: {
    currentProjet: (state): any => {
      return useProjetsStore().currentProjet;
    },
  },
  actions: {
    async fetch() {
      if (this.currentProjet) {
        const res = await useApi.ccis.findOne(this.currentProjet.cci.id);
        if (res && res.data) {
          this.cci = res.data;
        }
      }
    },

    async create(cci: Creatable<CCI>) {
      const res = await useApi.ccis.create(cci);
      if (res && res.data) {
        this.cci = res.data;
      }
    },

    async update(cci: Updatable<CCI>) {
      const res = await useApi.ccis.update(cci);
      if (res && res.data) {
        this.cci = res.data;
      }
      await useProjetsStore().fetchJalons();
    },

    async refresh() {
      if (this.cci) {
        await this.fetch();
        await useProjetsStore().fetchJalons();
      }
    },

    reset() {
      this.cci = null;
    },
  },
});
