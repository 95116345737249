import { useParametresStore } from '~~/stores/parametres';
import { ParametreKeys, PrecommandeTypes, RegimesTva, TypeConstructeur } from '~~/types/Enums';
import { DocumentPDF } from '~/src/models/DocumentPDF';
import type { Commande } from '~/types/models/commande';
import { useInformationsMarqueStore } from '~/stores/informationsMarque';
import { DateTime } from 'luxon';
import { type RowInput, type Styles } from 'jspdf-autotable';
import { useCommandesStore } from '~/stores/commandes';

const usePdfBdc = async (commande: Commande) => {
  const logoUrl = computed(() => {
    return useParametresStore().paramByKey(ParametreKeys.EDITION_LOGO);
  });

  const adresseMarque = useInformationsMarqueStore().adresseCompleteFlat;

  const isAvenant = !!commande.parent;

  const pdfName = `${isAvenant ? 'AVT_' : ''}BDC_${commande.reference}.pdf`;
  const pdf = new DocumentPDF(pdfName);

  const parent = (commande.parent as Commande) || null;

  const lot = commande.lignes[0].sousLot?.parent || null;

  await pdf.addHeader(logoUrl.value.valeur.split('::')[1]);

  const title =
    (commande.type === PrecommandeTypes.MT
      ? isAvenant
        ? `AVENANT AU MARCHE TRAVAUX ${parent?.reference || ''}`
        : 'MARCHE TRAVAUX'
      : 'BON DE COMMANDE') + (lot ? '\nLOT ' + lot.code + ' - ' + lot.libelle : '');

  pdf.addDocumentTitle(title);

  const clientsDatas: string[][] = [];
  if (commande.projet)
    commande.projet?.clients?.forEach((client: any) => {
      clientsDatas.push([`${client.civilite} ${client.nom} ${client.prenom}`]);
    });

  const adresseDatas: string[][] = [];
  if (commande.projet)
    adresseDatas.push(
      [],
      [
        `${commande.projet?.adresse_ligne}\n${commande.projet?.adresse_cp} ${commande.projet?.adresse_commune}`,
      ],
    );

  if (clientsDatas.length > 0 && adresseDatas.length > 0) {
    pdf.addTable([['Chantier de :', '']], [...clientsDatas, ...adresseDatas], [70, 30]);
    pdf.addGap(1);
  }

  const { typeConstructeur, adresseComplete, raisonSociale } = useInformationsMarqueStore();

  const titreConstructeur =
    typeConstructeur === TypeConstructeur.CCMI ? 'Le Constructeur' : 'Le Contractant Général';

  const intervenantsHeaders = [[titreConstructeur, 'Entreprise']];

  const intervenantsDatas: string[][] = [];

  const ssttfour = commande.ssttfour;

  intervenantsDatas.push(
    [raisonSociale, ssttfour?.raisonSociale],
    [
      adresseComplete,
      `${ssttfour?.adresse_ligne}\n${ssttfour?.adresse_cp} ${ssttfour?.adresse_commune}`,
    ],
  );

  pdf.addTable(intervenantsHeaders, intervenantsDatas, [70, 30]);

  pdf.addGap(1);

  pdf.addTable(
    [['']],
    [
      [`Ref : ${commande.reference}`],
      [`Date : ${useDateFormat(DateTime.now().toISODate())}`],
      commande.dateLivraisonPrevue
        ? [`Date de livraison souhaitée : ${useDateFormat(commande.dateLivraisonPrevue)}`]
        : [],
    ],
    [100],
  );

  pdf.addGap(1);

  const mainHeaderStyle: Partial<Styles> = {
    fillColor: [0, 0, 0],
    textColor: [255, 255, 255],
    cellPadding: 2,
  };

  const lignesBySousLot = commande.lignes.reduce((acc: any, ligne: any) => {
    const sousLot = ligne.sousLot || null;
    const sousLotId = sousLot?.id || -1;
    if (!acc[sousLotId]) {
      acc[sousLotId] = { sousLot: sousLot, lignes: [] };
    }
    acc[sousLotId].lignes.push(ligne);
    return acc;
  }, {});

  const mainColor = useParametresStore().paramByKey(ParametreKeys.MAIN_COLOR).valeur;
  const sousLotHeaderStyle: Partial<Styles> = {
    fillColor: mainColor,
    textColor: [255, 255, 255],
    cellPadding: 2,
  };
  const lignesDatas: RowInput[] = [];
  const sousLotHeaders: RowInput[] = [
    [
      { content: 'Désignation', styles: mainHeaderStyle },
      { content: 'U.', styles: { ...mainHeaderStyle, halign: 'center' } },
      { content: 'Quantité', styles: { ...mainHeaderStyle, halign: 'center' } },
      { content: 'Prix unit. € H.T.', styles: { ...mainHeaderStyle, halign: 'center' } },
      { content: 'Montant € H.T.', styles: { ...mainHeaderStyle, halign: 'right' } },
    ],
  ];
  Object.values(lignesBySousLot).forEach((lignesBySousLot: any, index) => {
    const { sousLot, lignes } = lignesBySousLot;

    lignesDatas.push([
      {
        content: sousLot ? `${sousLot.code} - ${sousLot.libelle}` : '',
        colSpan: 5,
        styles: sousLotHeaderStyle,
      },
    ]);

    lignes.forEach((ligne: any, index: number) => {
      const { description, gamme, descriptionComplementaire, unite, quantite, prixUnitaire, prix } =
        ligne;
      lignesDatas.push([
        {
          content: `${description}${gamme ? '\n' + gamme : ''}${
            descriptionComplementaire ? '\n' + descriptionComplementaire : ''
          }`,
          styles: {
            lineWidth: {
              bottom: index === lignes.length - 1 ? 0 : 0.1,
              top: index === 0 ? 0 : 0.1,
            },
            fillColor: [255, 255, 255],
            lineColor: 0,
            cellPadding: 2,
          },
        },
        {
          content: unite || '',
          styles: {
            cellPadding: 2,
            halign: 'center',
            valign: 'top',
            lineWidth: {
              bottom: index === lignes.length - 1 ? 0 : 0.1,
              top: index === 0 ? 0 : 0.1,
            },
            fillColor: [255, 255, 255],
            lineColor: 0,
          },
        },
        {
          content: useRound(quantite),
          styles: {
            cellPadding: 2,
            halign: 'center',
            valign: 'top',
            lineWidth: {
              bottom: index === lignes.length - 1 ? 0 : 0.1,
              top: index === 0 ? 0 : 0.1,
            },
            fillColor: [255, 255, 255],
            lineColor: 0,
          },
        },
        {
          content: useCurrencyFormat(prixUnitaire, ''),
          styles: {
            cellPadding: 2,
            halign: 'center',
            valign: 'top',
            lineWidth: {
              bottom: index === lignes.length - 1 ? 0 : 0.1,
              top: index === 0 ? 0 : 0.1,
            },
            fillColor: [255, 255, 255],
            lineColor: 0,
          },
        },
        {
          content: useCurrencyFormat(prix, ''),
          styles: {
            cellPadding: 2,
            halign: 'right',
            valign: 'top',
            lineWidth: {
              bottom: index === lignes.length - 1 ? 0 : 0.1,
              top: index === 0 ? 0 : 0.1,
            },
            fillColor: [255, 255, 255],
            lineColor: 0,
          },
        },
      ]);
    });

    // ligne total sous lot
    lignesDatas.push([
      {
        content: sousLot ? `Total ${sousLot.code} - ${sousLot.libelle}` : 'Total',
        colSpan: 4,
        styles: {
          fontStyle: 'bold',
          lineWidth: {
            top: 0.4,
          },
          fillColor: [255, 255, 255],
          lineColor: 0,
          cellPadding: 2,
        },
      },
      {
        content: useCurrencyFormat(
          lignes.reduce((acc: number, ligne: any) => acc + ligne.prix, 0),
          '',
        ),
        styles: {
          fontStyle: 'bold',
          cellPadding: 2,
          halign: 'right',
          valign: 'top',
          lineWidth: {
            top: 0.4,
          },
          fillColor: [255, 255, 255],
          lineColor: 0,
        },
      },
    ]);
  });

  pdf.addTable(sousLotHeaders, lignesDatas, [60, 5, 10, 12.5, 12.5], {
    margin: { top: pdf.verticalMargin },
    styles: {
      lineWidth: 0,
    },
  });

  pdf.addGap(1);

  // TOTAUX

  if (pdf.checkIfPageBreakIsNeeded(pdf.yOffset, 'X\nX\nX\n', 12)) {
    pdf.addPageBreak();
  }

  const totaux: RowInput[] = [];

  totaux.push([
    { content: 'Montant total H.T. : ', colSpan: 2, styles: { halign: 'right', fontSize: 12 } },
    {
      content: useCurrencyFormat(useCommandesStore().montantTotalHt(commande)),
      styles: { fontStyle: 'bold', fontSize: 12, halign: 'right' },
    },
  ]);

  const isTVA = commande.ssttfour?.regimeTva === RegimesTva.REEL;

  if (isTVA) {
    totaux.push([
      {
        content: `T.V.A. ${useCurrencyFormat(useCommandesStore().tvaPercent(commande), '%')} : `,
        colSpan: 2,
        styles: { halign: 'right', fontSize: 12 },
      },
      {
        content: useCurrencyFormat(
          useMontantTva(
            useCommandesStore().montantTotalHt(commande),
            useCommandesStore().tauxTva(commande),
          ),
        ),
        styles: { fontStyle: 'bold', fontSize: 12, halign: 'right' },
      },
    ]);

    totaux.push([
      {
        content: 'Montant total T.T.C. : ',
        colSpan: 2,
        styles: { halign: 'right', fontSize: 12 },
      },
      {
        content: useCurrencyFormat(useCommandesStore().montantTotalTtc(commande)),
        styles: { fontStyle: 'bold', fontSize: 12, halign: 'right' },
      },
    ]);
  }

  pdf.addTable([['', '', '']], totaux, [50, 30, 20]);

  // MENTIONS TVA

  if (!isTVA) {
    pdf.addGap(1);

    pdf.addParagraphe(
      "La TVA est due par \"l'entrepreneur Sous-traitant\" sur la base d'un régime d'audirective n° 2006/112/CE ou à l'article 283-2 du Code général des impôts.",
    );
  }

  // NOTES

  if (commande.commentaires) {
    if (
      pdf.checkIfPageBreakIsNeeded(pdf.yOffset, commande.commentaires, pdf.getLevelFontSize('p'))
    ) {
      pdf.addPageBreak();
    }

    pdf.addTable(
      [['']],
      [[{ content: commande.commentaires, styles: { lineWidth: 0.2, cellPadding: 4 } }]],
      [100],
    );
  }

  pdf.addGap(1);

  const isMT = commande.type === PrecommandeTypes.MT;

  if (isMT && commande.projet) {
    // ajout du PPCST

    pdf.addPageBreak();

    const param = useParametresStore().paramByKey(ParametreKeys.PPCST_CONTENT);
    const parsedMd = useMarkdownTextParser(param?.valeur || '');

    const commandeLot = commande.lignes[0].sousLot?.parent;

    for (const item of parsedMd) {
      switch (item.type) {
        case 'p':
          pdf.addParagraphe(
            useTextVariablesReplacer(item.value, {
              ssttfour: commande?.ssttfour,
              lot: commandeLot,
              projet: commande?.projet,
              commande: commande || undefined,
            }),
          );
          break;
        case 'h':
          pdf.addHeading(item.value, item.level);
          break;
        default:
          break;
      }
    }

    pdf.addGap(1);
  }

  // SIGNATURES

  if (pdf.checkIfPageBreakIsNeeded(pdf.yOffset, adresseMarque, pdf.getLevelFontSize('p'))) {
    pdf.addPageBreak();
  }

  pdf.addTable(
    [['']],
    [[adresseMarque], isMT ? [`Le ${useDateFormat(DateTime.now().toISODate())}`] : []],
    [100],
  );

  pdf.addGap(0.5);

  const contenuSign = {
    content: 'Signatures précédées de la mention\n"Lu et approuvé, bon pour accord"',
    styles: { fontSize: 8 },
  };

  pdf.addTable(
    [[titreConstructeur, isMT ? 'Entreprise' : '']],
    [[isMT ? contenuSign : '', isMT ? contenuSign : '']],
    [50, 50],
  );

  pdf.addGap(5);

  // FOOTER

  const infosMarque = useInformationsMarqueStore().infos;
  const footerContent = infosMarque
    ? `${infosMarque.raisonSociale} - ${infosMarque.adresse.replace('\n', ' ')}\n${
        infosMarque.formeJuridique
      } au capital de ${infosMarque.capitalSocial} €\nSiret n° ${infosMarque.siret} - RCS ${
        infosMarque.rcs
      }`
    : '';

  pdf.printFooters(footerContent, true);

  return pdf;
};

export { usePdfBdc };
