import { usePvHtOption } from '~/composables/metres/options/usePvHtOption';
import type { ArticleLibreAvenant, Avenant } from '~/types/models/avenant';
import type { OptionMetre } from '~/types/models/optionMetre';

type TvaAccumulator = { [key: number]: number };

export const usePvTtcAvenant = (avenant: Avenant) => {
  let pvsHtByTva: TvaAccumulator =
    avenant.options?.reduce((acc: TvaAccumulator, option: OptionMetre) => {
      const pvHt = usePvHtOption(option);
      const tva = option.tva;
      if (!acc[tva]) {
        acc[tva] = 0;
      }
      acc[tva] += pvHt;
      return acc;
    }, {}) || ({} as TvaAccumulator);

  pvsHtByTva =
    avenant.articlesLibres?.reduce(
      (acc: { [key: number]: number }, article: ArticleLibreAvenant) => {
        const pvHt = useUnapplyTva(article.pvTTC, useTauxTva(article.tva));
        const tva = article.tva;
        if (!acc[tva]) {
          acc[tva] = 0;
        }
        acc[tva] += pvHt;
        return acc;
      },
      pvsHtByTva,
    ) || ({} as TvaAccumulator);

  const tvas = Object.keys(pvsHtByTva);

  return tvas.reduce((acc: number, key: string) => {
    const tva = parseFloat(key);
    const pvHt = pvsHtByTva[tva];
    return acc + useApplyTva(pvHt, useTauxTva(tva));
  }, 0);
};
