import type { LigneMetre } from '~/types/models/ligneMetre';
import { usePvTtcLignes } from './usePvTtcLignes';

export const usePvTtcLignesParLot = (lignes: LigneMetre[], codeLot: string) => {
  const lignesLot = lignes.filter(
    (l) => l.article?.sous_lot?.code === codeLot || l.article?.lot?.code === codeLot,
  );

  return usePvTtcLignes(lignesLot);
};
