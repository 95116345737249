export const useMarkdownTextParser = (text: string) => {
  const result: { type: 'h' | 'p'; level: string; value: string }[] = [];
  // read text line by line and store it in an array
  const lines = text.split('\n\n');
  // if the line start with a #, it's a heading. store it in the result array
  lines.forEach((line) => {
    if (line !== '')
      if (line.startsWith('#')) {
        const hashMatches = line.match(/^(#+)/);
        const level = hashMatches ? hashMatches[0].length.toString() : 'p';
        const value = line.replace(/^(#+)\s/, '');
        result.push({ type: 'h', level, value });
      } else {
        result.push({ type: 'p', level: 'p', value: line });
      }
  });

  return result;
};
