import { DateTime } from 'luxon';
import { useAuthStore } from '~/stores/auth';
import { useCommandesStore } from '~/stores/commandes';
import { TypePrestation } from '~/types/Enums';
import type { Commande } from '~/types/models/commande';
import type { Metre } from '~/types/models/metre';
import type { Ssttfour } from '~/types/models/ssttfour';
import { usePvNonComprisTtcMetre } from './metres/usePvNonComprisTtcMetre';

export type VarsContext = {
  ssttfour?: Ssttfour;
  lot?: any;
  projet?: any;
  commande?: Commande;
  metre?: Metre;
};

export const useTextVariableResolver = (variable: string, ctx: VarsContext) => {
  const workspace = useAuthStore().workspace as any;

  switch (variable) {
    case 'RAISON_SOCIALE':
      return workspace?.informations_marque?.raisonSociale || variable;
    case 'CAPITAL_SOCIAL':
      return useCurrencyFormat(workspace?.informations_marque?.capitalSocial || variable);
    case 'RCS':
      return workspace?.informations_marque?.rcs || variable;
    case 'ADRESSE':
      return workspace?.informations_marque?.adresse?.replace('\n', ' ') || variable;
    case 'PRENOM_REPRESENTANT':
      return workspace?.informations_marque?.representant_prenom || variable;
    case 'NOM_REPRESENTANT':
      return workspace?.informations_marque?.representant_nom || variable;
    case 'QUALITE_REPRESENTANT':
      return workspace?.informations_marque?.representant_qualite || variable;
    case 'RAISON_SOCIAL_FOURNISSEUR':
      return ctx.ssttfour?.raisonSociale || variable;
    case 'ADRESSE_FOURNISSEUR':
      return ctx.ssttfour?.adresse_ligne &&
        ctx.ssttfour?.adresse_cp &&
        ctx.ssttfour?.adresse_commune
        ? `${ctx.ssttfour?.adresse_ligne} ${ctx.ssttfour?.adresse_cp} ${ctx.ssttfour?.adresse_commune}`
        : variable;
    case 'LOT':
      return ctx.lot ? ctx.lot.code + ' - ' + ctx.lot.libelle : variable;
    case 'ADRESSE_PROJET':
      return ctx.projet?.adresse_ligne && ctx.projet?.adresse_cp && ctx.projet?.adresse_commune
        ? `${ctx.projet?.adresse_ligne} ${ctx.projet?.adresse_cp} ${ctx.projet?.adresse_commune}`
        : variable;

    case 'NOM_COMPLET_CLIENTS_PROJET':
      return (
        ctx.projet?.clients.map((client: any) => `${client.prenom} ${client.nom}`).join(' et ') ||
        variable
      );

    case 'MATERIAUX_FOURNIS':
      const hasOnlyPO = ctx.commande?.lignes.every((l) => l.type_prestation === TypePrestation.PO);
      return hasOnlyPO ? 'OUI' : 'NON';
    case 'TOTAL_COMMANDE':
      return ctx.commande
        ? useCurrencyFormat(useCommandesStore().montantTotalHt(ctx.commande))
        : variable;

    case 'DATE_DU_JOUR':
      return useDateFormat(DateTime.now().toISODate());

    case 'PV_TTC_NON_COMPRIS':
      return ctx.metre ? useCurrencyFormat(usePvNonComprisTtcMetre(ctx.metre)) : variable;

    default:
      return variable;
  }
};

export const useTextVariablesReplacer = (text: string, ctx: VarsContext) => {
  // extract variables from text into an array. variables are in the format {{VARIABLE}}
  const variables = text.match(/{{(.*?)}}/g);
  // if there are no variables, return the text as is
  if (!variables) return text;
  // for each variable, replace it with its value
  variables.forEach((variable) => {
    text = text.replace(variable, useTextVariableResolver(variable.replace(/{{|}}/g, ''), ctx));
  });
  return text;
};
