import type { LigneMetre } from '~/types/models/ligneMetre';

export const useSortLignesByTypeArticle = (a: LigneMetre, b: LigneMetre) => {
  if (!a.article || !b.article) return 0;
  const [typeA, sousTypeA, typeB, sousTypeB] = [
    a.article.type?.parent,
    a.article.type,
    b.article.type?.parent,
    b.article.type,
  ];
  const [comp1, comp2] = [
    (sousTypeA?.ordre || 0) - (sousTypeB?.ordre || 0),
    (typeA?.ordre || 0) - (typeB?.ordre || 0),
  ];
  return comp1 || comp2;
};
