import { ParametreTypes } from '~/types/Enums';
import { useParamByKey } from './useParamByKey';
import type { ParamSection, ParamValue } from '~/types/Parametre';

const flattenParams = (params: ParamSection[]): ParamValue[] => {
  return params.reduce((acc, section) => {
    const sectionParams = section.params.map((param) => param);
    const sousSectionParams =
      section.sousSections?.map((subSection) => flattenParams([subSection])) || [];
    return [...acc, ...sectionParams, ...sousSectionParams.flat()];
  }, [] as ParamValue[]);
};

export const useParamValue = (key: string, params?: ParamSection[]) => {
  let param;
  if (params) {
    const flatParams = flattenParams(params);
    param = flatParams.find((p) => p.clef === key);
  } else {
    param = useParamByKey(key);
  }
  if (param?.type === ParametreTypes.BOOLEAN)
    return (param?.valeur as string)?.toLowerCase() === 'true';
  if (param?.type === ParametreTypes.TEXT || param?.type === ParametreTypes.TEXTAREA)
    return (param?.valeur as string).replaceAll('\\n', '\n') ?? null;
  return param?.valeur ?? null;
};
