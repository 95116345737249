import type { Contrat } from '~/types/models/contrat';
import { usePvTtcAvenant } from './avenants/usePvTtcAvenant';
import { AvenantStatus } from '~/types/Enums';

export const usePvTtcAvenantsSignes = (contrat: Contrat) => {
  return (
    contrat?.avenants
      ?.filter((a) => a.statut === AvenantStatus.SIGNE)
      .reduce((acc: number, avenant: any) => {
        return acc + usePvTtcAvenant(avenant);
      }, 0) || 0
  );
};
