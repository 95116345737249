import { FactureClientStatus } from '~/types/Enums';
import type { Facturation, FactureClient } from '~/types/models/factureClient';

export const useFacturationFacturesReglees = (
  facture: FactureClient,
  facturation: Facturation,
  generate: boolean = false,
) => {
  if (!generate) return facture.facturesReglees || [];

  return (
    facturation.factures
      ?.filter(
        (f) =>
          f.reference !== facture.reference &&
          (f.statut === FactureClientStatus.REGLE ||
            f.statut === FactureClientStatus.REGLEMENT_PARTIEL),
      )
      .map((f) => ({
        reference: f.reference,
        montant: f.reglements?.reduce((acc, r) => acc + r.montant, 0) || 0,
      })) || []
  );
};
