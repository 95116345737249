import { left, right } from '@popperjs/core';
import jsPDF, { type jsPDFOptions } from 'jspdf';
import autoTable, { type RowInput, type Styles, type UserOptions } from 'jspdf-autotable';

export type DocumentPdfOptions = {
  orientation: 'portrait' | 'landscape';
};

export class DocumentPDF {
  name: string;
  pdf: jsPDF;

  isPortrait: boolean;

  horizontalMargin: number;
  verticalMargin: number;
  verticalGap: number;

  documentTitleFontSize: number;

  fontSizes: { [level: string]: number } = {};

  yOffset: number;

  headerLogoHeight: number;

  constructor(name: string, options?: DocumentPdfOptions) {
    if (!options) {
      options = { orientation: 'portrait' };
    }

    this.isPortrait = options.orientation === 'portrait';

    this.name = name;

    this.horizontalMargin = this.isPortrait ? 30 : 10;
    this.verticalMargin = this.isPortrait ? 30 : 10;
    this.verticalGap = 15;

    this.yOffset = this.verticalMargin;

    this.documentTitleFontSize = this.isPortrait ? 20 : 14;

    this.fontSizes['1'] = 16;
    this.fontSizes['2'] = 12;
    this.fontSizes['3'] = 10;
    this.fontSizes['p'] = 9;

    this.headerLogoHeight = this.isPortrait ? 54 : 25;

    (jsPDF as any).autoTableSetDefaults({
      margin: {
        left: this.horizontalMargin,
        right: this.horizontalMargin,
        top: 0,
        bottom: 0,
      },
    });

    this.pdf = new jsPDF({
      unit: 'px',
      orientation: options.orientation,
    });
  }

  getColumnCellStyle(percentWidth: number): Partial<Styles> {
    return {
      cellWidth: this.getPercentOfPageWidthXPosition(percentWidth),
      valign: 'middle',
      cellPadding: {
        vertical: 1,
        horizontal: 2,
      },
    };
  }

  async addHeader(logoUrl: string, title?: string, subTitle?: string) {
    const logo = await useLoadImage(logoUrl);
    if (logo) {
      const ratio = logo.width / logo.height;

      const logoWidth = this.headerLogoHeight * ratio;

      this.pdf.addImage(
        logo,
        'PNG',
        this.getAlignRightXPosition(logoWidth),
        this.yOffset,
        logoWidth,
        this.headerLogoHeight,
      );

      this.yOffset += this.headerLogoHeight + this.getVerticalGapSize(this.isPortrait ? 1.5 : 0.5);

      this.pdf.setLineWidth(this.isPortrait ? 1 : 0.5);

      this.pdf.line(
        this.horizontalMargin,
        this.yOffset,
        this.isPortrait
          ? this.getPercentOfPageWidthXPosition(70) + this.getVerticalGapSize(1.5)
          : this.getAlignRightXPosition(0),
        this.yOffset,
      );

      this.yOffset += this.getVerticalGapSize(1);

      if (title) {
        this.addDocumentTitle(title);
      }

      if (subTitle) {
        this.addDocumentSubTitle(subTitle);
      }
    }
  }

  addDocumentTitle(title: string): void {
    autoTable(this.pdf, {
      startY: this.isPortrait ? this.yOffset : this.verticalMargin,
      body: [[title]],
      theme: 'plain',
      bodyStyles: { fontSize: this.documentTitleFontSize, cellPadding: 0 },
    });

    this.yOffset = this.lastYOffset() + this.getVerticalGapSize(1);
  }

  addDocumentSubTitle(title: string): void {
    autoTable(this.pdf, {
      startY: this.yOffset + this.getVerticalGapSize(-0.5),
      body: [[title]],
      theme: 'plain',
      bodyStyles: { fontSize: this.fontSizes['3'], cellPadding: 0, textColor: 150 },
    });

    this.yOffset = this.lastYOffset() + this.getVerticalGapSize(1);
  }

  addHeading(text: string, level: string): void {
    if (this.checkIfPageBreakIsNeeded(this.yOffset, text, this.getLevelFontSize(level))) {
      this.addPageBreak();
    }

    autoTable(this.pdf, {
      startY: this.yOffset,
      body: [[text]],
      theme: 'plain',
      bodyStyles: { fontSize: this.getLevelFontSize(level), cellPadding: 0 },
    });

    this.yOffset = this.lastYOffset() + this.getVerticalGapSize(1);
  }

  addParagraphe(text: string): void {
    if (this.checkIfPageBreakIsNeeded(this.yOffset, text, this.getLevelFontSize('p'))) {
      this.addPageBreak();
    }

    autoTable(this.pdf, {
      startY: this.yOffset,
      body: [[text]],
      theme: 'plain',
      bodyStyles: { fontSize: this.getLevelFontSize('p'), cellPadding: 0 },
    });

    this.yOffset = this.lastYOffset() + this.getVerticalGapSize(1);
  }

  getLevelFontSize(level: string): number {
    return level in this.fontSizes ? this.fontSizes[level] : this.fontSizes['p'];
  }

  lastYOffset(): number {
    return (this.pdf as any).lastAutoTable.finalY;
  }

  getEffectivePageWIdth(): number {
    return this.pdf.internal.pageSize.width - 2 * this.horizontalMargin;
  }

  getAlignRightXPosition(elementWidth: number): number {
    return this.pdf ? this.getEffectivePageWIdth() + this.horizontalMargin - elementWidth : 0;
  }

  getPercentOfPageWidthXPosition(percent: number): number {
    return this.pdf ? this.getEffectivePageWIdth() * (percent / 100) : 0;
  }

  getVerticalGapSize(times = 1): number {
    return this.verticalGap * times;
  }

  addPageBreak() {
    this.pdf.addPage();
    this.yOffset = this.verticalMargin;
  }

  checkIfPageBreakIsNeeded(yOffset: number, text: string, fontSize: number): boolean {
    return (
      yOffset + this.getTextDimensions(text, fontSize).h >
      this.pdf.internal.pageSize.height - this.verticalMargin
    );
  }

  getTextDimensions(text: string, fontSize: number) {
    const nbNewlines = text.split('\n').length;
    const lineDimensions = this.pdf.getTextDimensions(text, {
      fontSize: fontSize,
    });

    return {
      w: lineDimensions.w,
      h: lineDimensions.h * nbNewlines,
    };
  }

  addTable(
    headers: RowInput[],
    rows: RowInput[],
    columnWidths: number[],
    tableConfig?: UserOptions,
    bodyStyles?: Partial<Styles>,
  ): void {
    const { $_ } = useNuxtApp();

    const columnsStyles: { [key: number]: Partial<Styles> } = {};
    columnWidths.forEach((w, index) => {
      columnsStyles[index] = this.getColumnCellStyle(w);
    });

    const config: UserOptions = {
      useCss: true,
      startY: this.yOffset,
      head: headers,
      body: rows,
      margin: {
        left: this.horizontalMargin,
        right: this.horizontalMargin,
        top: this.verticalMargin,
        bottom: 2 * this.verticalMargin,
      },
      columnStyles: columnsStyles,
      theme: 'plain',
      headStyles: {
        fontStyle: 'bold',
        fontSize: this.fontSizes['p'],
        cellPadding: { vertical: 1, left: 0 },
      },
      bodyStyles: $_.merge({ fontSize: this.fontSizes['p'] }, bodyStyles),
    };

    let fullConfig = config;

    if (tableConfig) {
      fullConfig = $_.merge(config, tableConfig);
    }

    autoTable(this.pdf, fullConfig);

    this.yOffset = this.lastYOffset();
  }

  addGap(times = 1): void {
    this.yOffset += this.getVerticalGapSize(times);
  }

  download(document: Document): void {
    const pdfResult = this.pdf.output('dataurlstring', {
      filename: this.name,
    });

    let a = document.createElement('a');
    a.href = pdfResult;
    a.download = this.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  preview(): void {
    this.pdf.output('dataurlnewwindow', { filename: this.name });
  }

  getAsBlob(): Blob {
    return this.pdf.output('blob');
  }

  printFooters(texte: string, printOnFirstPage = false) {
    const pageCount = this.pdf ? (this.pdf as any).internal.getNumberOfPages() : 0;
    const start = printOnFirstPage ? 1 : 2;
    for (let i = start; i <= pageCount; i++) {
      this.pdf.setPage(i);
      this.addTable(
        [['', '', '']],
        [
          [
            '',
            texte,
            {
              content: `${i}/${pageCount}`,
              styles: { halign: 'right', valign: 'bottom', fontSize: this.fontSizes['p'] },
            },
          ],
        ],
        [20, 60, 20],
        {
          startY:
            this.pdf.internal.pageSize.height -
            this.getTextDimensions(texte, 6).h -
            this.verticalGap -
            10,
          styles: {
            halign: 'center',
          },
          margin: {
            top: 0,
            bottom: 0,
          },
          bodyStyles: {
            textColor: 150,
            fontSize: 6,
            cellPadding: 0,
          },
        },
      );
    }
  }
}
