import type { LigneMetre } from '~/types/models/ligneMetre';
import { useDeboursLigne } from './useDeboursLigne';

export const useDeboursLignesParLot = (lignes: LigneMetre[], codeLot: string) => {
  return lignes.reduce((acc, l) => {
    if (l.compris && (l.article?.sous_lot?.code === codeLot || l.article?.lot?.code === codeLot)) {
      acc += useDeboursLigne(l);
    }
    return acc;
  }, 0);
};
