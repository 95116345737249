import { FactureClientStatus } from '~/types/Enums';
import type { Facturation, FactureClient } from '~/types/models/factureClient';

export const useFacturationFacturesEnAttentes = (
  facture: FactureClient,
  facturation: Facturation,
  generate: boolean = false,
) => {
  if (!generate) return facture.facturesEnAttentes || [];

  return (
    facturation.factures
      ?.filter(
        (f) =>
          f.reference !== facture.reference &&
          f.date &&
          (f.statut === FactureClientStatus.REGLEMENT_PARTIEL ||
            f.statut === FactureClientStatus.ENVOYE ||
            f.statut === FactureClientStatus.A_ENVOYER),
      )
      .map((f) => ({
        reference: f.reference,
        montant: useRound(
          f.montantTheorique - (f.reglements?.reduce((acc, r) => acc + r.montant, 0) || 0),
        ),
      })) || []
  );
};
