export default async (url: string): Promise<HTMLImageElement | null> => {
  return new Promise((resolve) => {
    const logo = new Image();
    logo.onload = () => {
      resolve(logo);
    };
    logo.onerror = (err) => {
      resolve(null);
    };

    logo.src = url + '?' + new Date().getTime();
  });
};
